export enum eRTable {
  'chatMessage' = 'chatMessage',
  'chats' = 'chats',
  'comments' = 'comments',
  'companies' = 'companies',
  'diagnosticsTitles' = 'diagnosticsTitles',
  'estimates' = 'estimates',
  'fcmToken' = 'fcmToken',
  'flespiCreds' = 'flespiCreds',
  'flespiMessages' = 'flespiMessages',
  'inspections' = 'inspections',
  'notifications' = 'notifications',
  'obd2Devices' = 'obd2Devices',
  'obd2HarshEvents' = 'obd2HarshEvents',
  'preInspTitles' = 'preInspTitles',
  'preTripInspections' = 'preTripInspections',
  'postTripInspections' = 'postTripInspections',
  'reminderProfiles' = 'reminderProfiles',
  'reminders' = 'reminders',
  'repairForms' = 'repairForms',
  'requests' = 'requests',
  'timeSheet' = 'timeSheet',
  'users' = 'users',
  'vehicleCategories' = 'vehicleCategories',
  'vehicleStats' = 'vehicleStats',
  'vehicles' = 'vehicles',
  'routes' = 'routes',
  'version' = 'version',
  'videoLibrary' = 'videoLibrary',
  'playlists' = 'playlists',
  'pointsOfInterest' = 'pointsOfInterest',
  'geoFence' = 'geoFences',
  'poiEvents' = 'poiEvents',
  'geofenceEvents' = 'geofenceEvents',
  'preRentalInspection' = 'preRentalInspection',
  'postRentalInspection' = 'postRentalInspection',
  'maintenanceProfiles' = 'maintenanceProfiles',
  'maintenanceReminders' = 'maintenanceReminders',
  'fleetHealth' = 'fleetHealth',
  'jimChats' = 'jimChats',
  'lastServiceDetails' = 'lastServiceDetails',
  'metadata' = 'metadata',
  'preLoanInspection' = 'preLoanInspection',
  'postLoanInspection' = 'postLoanInspection',
}
