import { eRRoles } from '../enums/eRRoles';

export const RaCONSTS = {
  tireDiameter: [
    '10',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '16.5',
    '17.5',
    '19.5',
    '22.5',
    '24.5',
  ],
 mddtyreDiameters:[
    '225/70R19.5',
    '245/70R19.5',
    '265/70R19.5',
    '275/70R22.5',
    '285/70R19.5',
    '225/75R16',
  ],
  HDTTireDiameters: [
    '11R22.5',
    '12R22.5',
    '13R22.5',
    '275/80R22.5',
    '295/75R22.5',
    '305/70R22.5',
    '315/80R22.5',
    '11R24.5',
    '285/75R24.5',
  ],
  tireRatio: [
    'None',
    '20',
    '25',
    '30',
    '35',
    '40',
    '45',
    '50',
    '55',
    '60',
    '65',
    '70',
    '75',
    '80',
    '85',
    '90',
    '95',
    '7.5',
    '8.5',
    '9.5',
    '10.5',
    '11.5',
    '12.5',
    '13.5',
    '14',
    '14.5',
    '15.5',
    '16.5',
    '18.5',
    '0',
  ],
  tireWidth: [
    '105',
    '110',
    '115',
    '120',
    '125',
    '130',
    '135',
    '140',
    '145',
    '150',
    '155',
    '160',
    '165',
    '170',
    '175',
    '180',
    '185',
    '190',
    '195',
    '200',
    '205',
    '210',
    '215',
    '220',
    '225',
    '230',
    '235',
    '240',
    '245',
    '255',
    '260',
    '265',
    '270',
    '275',
    '280',
    '285',
    '290',
    '295',
    '300',
    '305',
    '310',
    '315',
    '320',
    '325',
    '330',
    '335',
    '340',
    '345',
    '350',
    '355',
    '375',
    '395',
    '405',
    '24X',
    '25X',
    '26X',
    '27X',
    '29X',
    '30X',
    '31X',
    '32X',
    '33X',
    '34X',
    '35X',
    '36X',
    '37X',
    '38X',
    '39X',
    '40X',
    '42X',
  ],
  PTireSubtypes: [
    'All Season',
    'Touring',
    'Winter',
    'Summer',
    'Performance',
    'Snow',
  ],
  LTTireSubtypes: [
    'All Season',
    'All Terrain',
    'Mud and Snow',
    'Snow Tires',
    'Highway Tires',
    'Ribbed Tires',
  ],
  MDTTireSubtypes: ['Steer', 'All Position', 'Highway', 'Traction'],
  HDTTireSubtypes: ['Steer', 'Drive', 'All Position', 'Highway', 'Traction'],
  vin: {
    make: 'Make',
    model: 'Model',
    series: 'Series',
    year: 'Model Year',
    type: 'Vehicle Type',
    dis: 'Displacement (L)',
    ftype: 'Fuel Type - Primary',
    brake: 'Engine Brake (hp) From',
    engineCylinders: 'Engine Number of Cylinders',
    engineModel: 'Engine Model',
    transmissionType: 'Transmission Style',
    driveType: 'Drive Type',
  },
  roles: {
    [eRRoles.admin]: 'Admin',
    [eRRoles.manager]: 'Fleet Manager',
    [eRRoles.mechanic]: 'Mechanic',
    [eRRoles.owner]: 'Owner',
    [eRRoles.user]: 'User',
  },
  phPattern: /(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/g,
  authErrors: {
    notFound: 'EMAIL_NOT_FOUND',
    userNotFound: 'auth/user-not-found',
  },
  appIcon: 'assets/app-icon.png',
  map: {
    id: '4504f8b37365c3d0',
    navIcon: `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m200-120-40-40 320-720 320 720-40 40-280-120-280 120Zm84-124 196-84 196 84-196-440-196 440Zm196-84Z"/></svg>`,
    iosFlag: `<svg width="92" height="105" viewBox="0 0 92 105" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="92" height="105" fill="#0E001F"/></svg>`,
    iosStart: `<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M49.5 0C39.825 0 32 7.825 32 17.5C32 30.625 49.5 50 49.5 50C49.5 50 67 30.625 67 17.5C67 7.825 59.175 0 49.5 0ZM37 17.5C37 10.6 42.6 5 49.5 5C56.4 5 62 10.6 62 17.5C62 24.7 54.8 35.475 49.5 42.2C44.3 35.525 37 24.625 37 17.5Z" fill="#0E001F"/><path d="M49.5 23.75C52.9518 23.75 55.75 20.9518 55.75 17.5C55.75 14.0482 52.9518 11.25 49.5 11.25C46.0482 11.25 43.25 14.0482 43.25 17.5C43.25 20.9518 46.0482 23.75 49.5 23.75Z" fill="#0E001F"/></svg>`,
    flag: `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M11,6H9V4h2V6z M15,4h-2v2h2V4z M9,14h2v-2H9V14z M19,10V8h-2v2H19z M19,14v-2h-2v2H19z M13,14h2v-2h-2V14z M19,4h-2v2h2 V4z M13,8V6h-2v2H13z M7,10V8h2V6H7V4H5v16h2v-8h2v-2H7z M15,12h2v-2h-2V12z M11,10v2h2v-2H11z M9,8v2h2V8H9z M13,10h2V8h-2V10z M15,6v2h2V6H15z"/></svg>`,
    defaultCenter: { lat: 39.28589063380064, lng: -77.20323727671781 },
    poi: {
      color: '#FF66CC',
      defaultRadius: 100,
    },
    fence: {
      color: '#7B5FE7',
    },
    obd2Alert:
      'Vehicle has not send data to server today. Please click on Alert to see analytics',
  },
  dateFormats: {
    mdy: 'MM/DD/YYYY',
    ymd: 'YYYY-MM-DD',
    md: 'MMM-DD',
    hs: '-',
    ss: '/',
    ll: 'LL',
  },
  hyphen: '-',
  colorCodes: {
    violet: '#45047A',
    violet75: '#c583fb',
    ppurple: '#8481DD',
    pgreen: '#7CC674',
    pyellow: '#F6D173',
    pred: '#f3797e',
    pblue: '#519DE9',
    porange: '#EF9234',
    black: '#212121',
    white: '#fff',
    red: '#cc3546',
    redd: '#e59aa3',
    text: '#7B5FE7',
    green: '#16b542',
    green75: '#8ef1aa',
    orange: '#fcae07',
    op: '#1C1B1F',
    grey: '#6B6B6B',
    gray: '#EAEAEA',
    overspeed: '#c03e00',
    brake: '#ff7517',
    corner: '#ffbe00',
    accel: '#f89880',
    lightViolet: '#f1e1fe',
    darkGrey: '#353535',
    yellow: '#FFFF00',
    navyBlue: '#000080',
    dBlue: '#0081DB',
    dyellow: '#ecdd59',
    speedCap: '#ff0000',
    idleCap: '#6DBDFF',
  },
  pagination: {
    count: 20,
  },
  icons: {
    traffic: `<svg class="ra-map-traffic" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000"><path d="M480-240q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0-180q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0-180q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17ZM280-360v-46q-51-14-85.5-56T160-560h120v-46q-51-14-85.5-56T160-760h120q0-33 23.5-56.5T360-840h240q33 0 56.5 23.5T680-760h120q0 56-34.5 98T680-606v46h120q0 56-34.5 98T680-406v46h120q0 56-34.5 98T680-206v6q0 33-23.5 56.5T600-120H360q-33 0-56.5-23.5T280-200v-6q-51-14-85.5-56T160-360h120Zm80 160h240v-560H360v560Zm0 0v-560 560Z"/></svg>`,
    eye: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>`,
    eyeClosed: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z"/></svg>`,
    layers: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000"><path d="M480-118 120-398l66-50 294 228 294-228 66 50-360 280Zm0-202L120-600l360-280 360 280-360 280Zm0-280Zm0 178 230-178-230-178-230 178 230 178Z"/></svg>`,
    fitScreen: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000"><path d="M800-600v-120H680v-80h120q33 0 56.5 23.5T880-720v120h-80Zm-720 0v-120q0-33 23.5-56.5T160-800h120v80H160v120H80Zm600 440v-80h120v-120h80v120q0 33-23.5 56.5T800-160H680Zm-520 0q-33 0-56.5-23.5T80-240v-120h80v120h120v80H160Zm80-160v-320h480v320H240Zm80-80h320v-160H320v160Zm0 0v-160 160Z"/></svg>`,
    play: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
  fill="#e8eaed"><path d="M320-200v-560l440 280-440 280Zm80-280Zm0 134 210-134-210-134v268Z" /></svg>`,
    pause: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M520-200v-560h240v560H520Zm-320 0v-560h240v560H200Zm400-80h80v-400h-80v400Zm-320 0h80v-400h-80v400Zm0-400v400-400Zm320 0v400-400Z" /></svg>`,
    start: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path
    d="M480-360q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm0 280q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>`,
    stop: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
    fill="#e8eaed"><path d="M320-640v320-320Zm-80 400v-480h480v480H240Zm80-80h320v-320H320v320Z" /></svg>`,
    office: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000"><path d="M80-120v-80h160v-160h-80v-80h84q12-75 66.5-129.5T440-636v-204h280v160H520v44q75 12 129.5 66.5T716-440h84v80h-80v160h160v80H80Zm240-80h120v-160H320v160Zm200 0h120v-160H520v160ZM326-440h308q-14-53-57-86.5T480-560q-54 0-97 33.5T326-440Zm154 0Z"/></svg>`,
    cancel: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>`,
  },
  rabbitTrail: {
    refreshInterval: 60,
  },
};
